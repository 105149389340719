

.loginForm {
  background-color: #0e4749;
  font-family: Arial;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
 }

.form {
  display: inline-block;
  width: 300px;
  margin-left: 37%;
}

.form label {
  display: block;
  font-size: 20px;
  color: #ffff;
  margin-left: 20px;
}

.form input {
  display: block;
  margin: 0 0 20px;
  padding: 10px 15px;
  width: 100%;
  border-radius: 30px;
  box-shadow: none;
  outline: none;
  border: 1px solid #0e4749;
}

.button {
  background-color: #ECA12A;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #EEBF74;
}

.error {
  color: red;
}

.success {
  color: green;
}

.centerForm {
  margin: 50px auto;
  width: 40%;
}

.header img {
  width: 100%;
}

.main img {
  height: 110px;
}
