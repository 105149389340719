
.BodEmpresas {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif, 'Times New Roman', Times, serif;
    background-color: #0E4749;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.formulario-funcao {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 95%;
    min-height: 90vh;
}

.header-formulario-Empresa {
    align-items: center;
    justify-content: center;
    background-color: #E4EBEB;
    color: #0E4749;
    padding: 10px;
    font-weight: bold;
}
.header-formulario-Empresa i {
    margin-right: 10px;
}
.formulario-Empresa h2 {
    color: #0E4749;
}
.formulario-Empresa label {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #195F62;
}
.formulario-Empresa label span {
    margin-right: 10px;
    width: 150px; /* Largura fixa dos rótulos */
    text-align: right; /* Alinhamento do texto dos rótulos */
    font-weight: bold;
}
.formulario-Empresa input[type="text"], .formulario-Empresa input[type="number"], .formulario-Empresa input[type="date"], .formulario-Empresa select {
    flex: 1; /* Ocupa o espaço restante */
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    width: calc(100% - 160px); /* Ajusta a largura dos inputs */
}
/* Cor do input quando focado */
.formulario-Empresa input[type="text"]:focus,
.formulario-Empresa input[type="number"]:focus,
.formulario-Empresa input[type="date"]:focus,
.formulario-Empresa select:focus {
    border-color: #2F8588; /* Altere para a cor desejada */
    outline: none; /* Remove a borda de foco padrão */
}
.btn-funcao {
    margin-top: 20%;
    margin-left: 110px;
}

.button-salvar {
    background-color: #0E4749;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-weight: bold;
    width: calc(28% - 200px);

}
.button-salvar:hover {
    background-color: #2F8588;
}

.button-cancelar {
    background-color: #fff;
    color: #0E4749;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #0E4749;
    box-sizing: border-box;
    font-weight: bold;
    width: calc(28% - 200px);
}

.MinhaLinha {
    width: 100%;
    height: 2px;
    background-color: #195F62;
    margin-top: 30px;
}


.textarea-descricao {
    width: 87%;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .textarea-descricao:focus {
    outline: none; /* Remove o contorno ao focar */
    border-color: #2F8588; /* Cor da borda ao focar */
  }