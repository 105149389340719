/* Adicione isso ao seu arquivo HomeInit.module.css */

.squareContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.square {
  width: calc(33.33% - 20px);
  height: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.square p {
  font-size: 20px;
}

.num {
  font-size: 80px;
  text-align: center;
  color: #0E4749;
}


.titleGrafico{
  color:#0E4749;
}

.progressBar {
  height: 20px; 
  background-color: #ddd;
  border-radius: 5px; 
  position: relative;
  width: 90%;
  margin-top: 10px;
}

.progressBackground {
  position: absolute; 
  top: 0; 
  left: 0; 
  height: 100%; 
  width: 100%; 
}

.progressForeground {
  background-color: #C3423F; 
  height: 100%; 
  border-radius: 5px; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  color: #fff; 
}


.body {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #0e4749;
}

.header img {
  max-width: 100%;
  height: auto;
}

.mainImage {
  display: flex;
  flex: 1;
  background-color: #0e4749;
  width: 250px;
  margin: 20px;
}

main {
  display: flex;
  flex: 1;
}

aside {
  background-color: #0e4749;
  color: #fff;
  width: 320px;
  padding-top: 20px;
}

nav {
  text-align: center;
}

.sectionImage {
  flex: 1 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px 0px 0px 0px;
  margin-top: -70px;
}


.link {
  color: #fff;
  text-decoration: none;
  padding: 13px;
  width: 85%;
  margin: 0 auto 10px;
  border-radius: 20px 0px 0px 20px;
  font-size: 22px;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 24px;
}

.icon {
  display: inline-block; 
  vertical-align: middle;
  margin-right: 30px;
}

.link:hover {
  background: #fff;
  color: #0E4749;
}



.separador {
  margin: 6px 0;
  border-top: 1px solid #ccc;
}

.subMenu {
  position: absolute;
  margin-top: 70%;
  left: calc(100% + 0px); /* Posiciona o submenu ao lado direito do botão "CADASTRO" */
  background-color: rgba(14, 71, 73, 0.9); /* Cor de fundo do submenu com opacidade reduzida */
  border: 1px solid #0e4749; /* Borda do submenu */
  border-radius: 4px; /* Bordas arredondadas */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
  padding: 5px; /* Espaçamento interno */
  min-width: 350px; /* Largura mínima do submenu */
  z-index: 10; /* Garante que o submenu esteja acima de outros elementos */
  font-size: 20px;
}

/* Estilo dos itens do submenu */
.subMenu a {
  display: block;
  color: #ffffff; /* Cor do texto do submenu */
  text-decoration: none;
  padding: 6px 2px; /* Espaçamento interno dos itens */
  transition: background-color 0.3s; /* Transição suave da cor de fundo */
  text-align: left;
  font-weight: 100;
}

/* Estilo dos itens do submenu quando hover */
.subMenu a:hover {
  background-color: #18797c; /* Cor de fundo ao passar o mouse */
  color: #ffffff;
  font-weight: bold;
}

/* Estilo do link "CADASTRO" quando o submenu está visível */
.link:hover .subMenu {
  display: block; /* Mostra o submenu quando o mouse passa por cima */
}

/* Estilo dos ícones e texto do menu */
.link {
  position: relative; /* Define como posição relativa para que o submenu absoluto funcione corretamente */
  cursor: pointer; /* Altera o cursor para um ponteiro ao passar o mouse */
}









.tabs {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-right: -20px;
  margin-left: -20px;
}

.tab {
  flex: 1 1;
  padding: 10px;
  background-color: #fff;
  color: #195F62;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-right: 1px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

.tab:hover {
  background-color: #fff;
}

.tab.active {
  background-color: #E4EBEB;
  color: #0E4749;
}

.sectionContent {
  padding: 20px;
}

/*<nav className={styles.tabs}>
<div
  className={`${styles.tab} ${activeTab === "Resumo diário" ? styles.active : ""}`}
  onClick={() => setActiveTab("Resumo diário")}
>
  Resumo diário
</div>
<div
  className={`${styles.tab} ${activeTab === "Equipamentos" ? styles.active : ""}`}
  onClick={() => setActiveTab("Equipamentos")}
>
  Equipamentos
</div>
<div
  className={`${styles.tab} ${activeTab === "Rotatividade" ? styles.active : ""}`}
  onClick={() => setActiveTab("Rotatividade")}
>
  Rotatividade
</div>
<div
  className={`${styles.tab} ${activeTab === "Perfil" ? styles.active : ""}`}
  onClick={() => setActiveTab("Perfil")}
>
  Perfil
</div>
<div
  className={`${styles.tab} ${activeTab === "Calendário" ? styles.active : ""}`}
  onClick={() => setActiveTab("Calendário")}
>
  Calendário
</div>
</nav>


{activeTab === "Resumo diário" && (
<section className={styles.sectionImage}>

</section>
)}

{activeTab === "Equipamentos" && (
<section className={styles.sectionImage}>

</section>
)}

{activeTab === "Rotatividade" && (
<section className={styles.sectionImage}>

</section>
)}

{activeTab === "Perfil" && (
<section className={styles.sectionImage}>

</section>
)}

{activeTab === "Calendário" && (
<section className={styles.sectionImage}>

</section>
)}*/