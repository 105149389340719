.app-body {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif, 'Times New Roman', Times, serif;
    background-color: #0E4749;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.formulario {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 95%;
    max-height: 90vh;
    overflow-y: auto;
}

.header-formulario {
    align-items: center;
    justify-content: center;
    background-color: #E4EBEB;
    color: #0E4749;
    padding: 10px;
    font-weight: bold;
}
.header-formulario i {
    margin-right: 10px;
}
.formulario h2 {
    color: #0E4749;
}
.formulario label {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #195F62;
}
.formulario label span {
    margin-right: 10px;
    width: 150px; /* Largura fixa dos rótulos */
    text-align: right; /* Alinhamento do texto dos rótulos */
    font-weight: bold;
}
.formulario input[type="text"], .formulario input[type="number"], .formulario input[type="date"], .formulario select {
    flex: 1; /* Ocupa o espaço restante */
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    width: calc(100% - 160px); /* Ajusta a largura dos inputs */
}
/* Cor do input quando focado */
.formulario input[type="text"]:focus,
.formulario input[type="number"]:focus,
.formulario input[type="date"]:focus,
.formulario select:focus {
    border-color: #2F8588; /* Altere para a cor desejada */
    outline: none; /* Remove a borda de foco padrão */
}
.btns {
    margin-top: 20px;
}

.button-salvar {
    background-color: #0E4749;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-weight: bold;
    width: calc(28% - 200px);

}
.button-salvar:hover {
    background-color: #2F8588;
}

.button-cancelar {
    background-color: #fff;
    color: #0E4749;
    border: none;
    cursor: pointer;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #0E4749;
    box-sizing: border-box;
    font-weight: bold;
    width: calc(28% - 200px);
}

.minhaLinha {
    width: 100%;
    height: 2px;
    background-color: #195F62;
    margin-top: 30px;
}

.imagem-quadrado {
    width: 190px; /* Largura da imagem quadrada */
    height: 150px; /* Altura da imagem quadrada */
    margin-left: auto; /* Move a imagem para a direita */
    background-color: #f0f0f0;
    border-radius: 8px;
    margin-top: 20px;
    margin-right: 40px;
}

.abas {
    display: flex;
    border-bottom: 2px solid #ABB3B3;
    margin-bottom: 10px;
    margin-top: 20px;
}

.aba {
    flex: 1;
    padding: 10px;
    background-color: #E4EBEB;
    color: #195F62;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
}

.aba:not(:last-child) {
    margin-right: 1px;
}

.aba:hover {
    background-color: #D0D5D5;
}

.conteudo-aba {
    display: none;
    padding: 10px;
}

.conteudo-aba.ativo {
    display: block;
}

.message {
    color: #f00;
    font-weight: bold;
    margin-left: 70%;
}