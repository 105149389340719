.bodyUse {
  display: flex;
  width: 100vw;
  min-height: 100vh; /* Alterado de height para min-height */
  background-color: #0E4749;
}

.menu-lateral {
    width: 210px;
    background-color: #0E4749;
    padding: 20px;
    font-size: 20px;
  }
  .menu-lateral ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menu-lateral li {
    margin-bottom: 10px;
  }
  
  .menu-lateral li a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .cadastro {
    flex: 1;
    padding: 20px;
  }
  
.cadastro {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    border: #ebebeb solid 1px;
    border-radius: 10px 0 0 0;
    margin-top: 10px;
}

.button-complet{
  background-color: #0E4749;
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #0E4749;
  box-sizing: border-box;
  font-weight: bold;
}

.separador {
    border-top: 2px solid #ccc;
    margin: 10px 0;
  }
  

.topo {
    width: 100%;
}

.barra {
    background-color: #E4EBEB;
    color: #0E4749;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 3px;
  }

.resultados{
    color: #104F51;
    font-weight: 400;
}

.barra p {
    font-weight: bold;
}

.barra i{
    font-size: 1.5rem;
}

.titulo i {
    margin-right: 10px;
}

.tituloRelat{
    color: #0E4749;
    font-weight: bold;
    margin: 18px;
}

.barra-inferior {
    justify-content: space-between;
    padding: 0 5px;
}

.busca {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
  }
  
  .busca input[type="search"] {
    font-size: 16px;
    border: 1px solid #e4ebeb;
    margin-right: 5px;
  }
  
  .busca img {
    width: 20px;
  }

.buscarNew{
    margin-right: 10px;
    color: #0e4749;
    font-weight: 500;
    font-size: 15px;
}

.table th, td, tr{
    border: #fff solid 1px;
    text-align: center;
    padding: 5px;
    
}
.table td {
    font-weight: 300;
    font-size: 0.8rem;
}

.table {
    width: 95%;
    border-collapse: collapse;
    margin-bottom: 10px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #E4EBEB;
    color: #104F51;
}

.table tr:nth-child(even) {
    background-color: #f2f2f2;
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-conteudo {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-conteudo p {
    margin-bottom: 10px;
  }
  
  .modal-conteudo button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #0E4749;
    color: white;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .modal-conteudo button:hover {
    background-color: #1A787B; 
  }
  
.titleModal{
        margin: 15px;
        color: #0E4749;
        font-weight: bold;
}

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .informacao-paginacao {
    margin-right: 330px;
    color: #0e4749;
    font-weight: 500;
    font-size: 15px;
  }
  
  .paginacao {
    margin-left: 330px;
  }
  
  .paginacao button {
        padding: 5px 10px;
        margin-right: 5px;
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        border-radius: 5px;
        cursor: pointer;
        color: #0e4749;
}
  
  .paginacao button:hover {
    background-color: #e0e0e0;
  }
  
  .paginacao button:active {
    background-color: #d0d0d0;
  }

  select {
    background-color: #fafafa;
    border: 0px solid #e4ebeb;
    padding: 0px 15px;
    border-radius: 4px;
    font-size: 16px;
    color: #0e4749;
    appearance: none;
    cursor: pointer;
    width: auto;
    outline: none;
  }

select:hover {
    border-color: #bbb;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-wrapper::after {
    content: '\25BC';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #333;
}


.calendario-selectors{
  margin-bottom: 20px;
}

/* Estilos CSS para o calendário */
.calendario {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Estilos CSS para os botões do calendário */
.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 8px 8px 0 0;
}

.react-calendar__navigation button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

/* Estilos CSS para os dias do mês */
.react-calendar__month-view__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #999;
}

.react-calendar__tile--active {
  background-color: #FD838D;
  color: #fff;
  border-radius: 0%;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #0E4749;
  color: #fff;
}

.react-calendar__tile--active:disabled {
  background-color: transparent;
  color: #999;
}

.react-calendar__tile--now {
  background-color: #f0f0f0;
  color: #0E4749;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #f0f0f0;
  color: #0E4749;
}

.react-calendar__tile--now:disabled {
  background-color: transparent;
  color: #999;
}
/* Estilos CSS para os dias da semana */
.react-calendar__month-view__weekdays {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: #FD838D; /* Cor de fundo para os dias da semana */
}

.react-calendar__month-view__weekdays__weekday abbr {
  color: #fff; /* Cor do texto para os dias da semana */
}
/* Estilos para as datas no calendário */
.react-calendar__month-view__days__day {
  font-size: 14px; /* Tamanho da fonte */
  padding: 10px; /* Espaçamento interno */
  cursor: pointer; /* Cursor ao passar por cima */
  transition: background-color 0.3s ease; /* Transição suave de cor de fundo */
}

.react-calendar__month-view__days__day--weekend {
  color: #FD838D; /* Cor para os fins de semana */
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #ccc; /* Cor para os dias do mês vizinho */
}

.react-calendar__tile--active {
  background-color: #FD838D; /* Cor de fundo quando a data é selecionada */
  color: #fff; /* Cor do texto quando a data é selecionada */
}

.react-calendar__tile--now {
  border: 1px solid #FD838D; /* Borda para a data atual */
  border-radius: 50%; /* Borda circular para a data atual */
}
/* Estilos para as datas no calendário */
.react-calendar__month-view__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px; /* Espaçamento entre os dias */
  justify-items: center; /* Centraliza os dias horizontalmente */
  align-items: center; /* Centraliza os dias verticalmente */
}

.react-calendar__month-view__days__day {
  font-size: 14px; /* Tamanho da fonte */
  padding: 10px; /* Espaçamento interno */
  cursor: pointer; /* Cursor ao passar por cima */
  transition: background-color 0.3s ease; /* Transição suave de cor de fundo */
  background-color: #fff;
}

.react-calendar__month-view__days__day--weekend {
  color: #0E4749; /* Cor para os fins de semana */
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #ccc; /* Cor para os dias do mês vizinho */
}

.react-calendar__tile--active {
  background-color: #FD838D; /* Cor de fundo quando a data é selecionada */
  color: #fff; /* Cor do texto quando a data é selecionada */
}

.react-calendar__tile--now {
  border: 1px solid #FD838D; /* Borda para a data atual */
  border-radius: 0%; /* Borda circular para a data atual */
}




@media print {
  .imprimir-nao {
    display: none;
  }
}




/* styles.css */

.calendario-list {
  list-style: none;
  padding: 0;
}

.calendario-list li {
  margin-bottom: 10px;
}

.calendario-list li:before {
  content: '•';
  margin-right: 5px;
}

.calendario-list li.aniversariante {
  font-weight: bold;
}

/* Exemplo de estilização para a tabela */
.tabela-aniversariantes {
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
}

.tabela-aniversariantes th, .tabela-aniversariantes td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.tabela-aniversariantes th {
  background-color: #FFEC70;
  color:#0E4749;
  text-align: center;
}

.tabela-aniversariantes tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tabela-aniversariantes tr:hover {
  background-color: #ddd;
}


